if (!window.webshop) window.webshop = {}
if (!frappe.boot) frappe.boot = {}
if (!frappe._messages) 

    //解决Web页面无法汉化问题，原因是frappe._messages没有值：2024/07/03

    frappe._messages = {};  
  
    // 使用 for...in 循环遍历 frappe.boot._messages 的属性  
    /*
    for (let key in frappe.boot._messages) {  
        // 确保 key 是 frappe.boot._messages 的自有属性，而不是原型链上的属性  
        if (frappe.boot._messages.hasOwnProperty(key)) {  
            // 将 frappe.boot._messages[key] 的值赋给 frappe._messages[key]  
            frappe._messages[key] = frappe.boot._messages[key];  
        }  
    } 
    */
   //浅拷贝
    frappe._messages = Object.assign({}, frappe.boot._messages);
